@import 'slick-carousel/slick/slick.css';
@import 'slick-carousel/slick/slick-theme.css';

@import './fonts/fonts.css';

form span.validation-error {
    color: #FF5630;
    font-family: "Montserrat","Helvetica","Arial",sans-serif;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.66;
    letter-spacing: 0.03333em;
    text-align: left;
    margin-top: 3px !important;
    margin-right: 14px;
    margin-bottom: 0;
    margin-left: 14px;
}
